<template>

<app-wrapper class="filter">

	<app-wrapper :content="true" class="filter-content">

		<h2>{{ $state.page.title }}</h2>

		<div class="filter-bar">

			<div class="filter-bar-label">Filter by</div>
			<div class="filter-bar-toggle" :class="{'is-active': model.events}" v-on:click="onToggleClick('events')">Events</div>
			<div class="filter-bar-toggle" :class="{'is-active': model.opinions}" v-on:click="onToggleClick('opinions')">Opinions</div>
			<div class="filter-bar-search"><input type="text" v-model="model.search" placeholder="Search keyword(s)" /><i class="fa-solid fa-magnifying-glass"></i></div>

		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			model: {
				events: false,
				opinions: false,
				search: ''
			}
		}

	},

	watch: {

		model: {

			deep: true,

			handler: function(n) {

				this.$emit('change', n)
					
			}

		}

	},

	created: function() {

		if (this.$route.query.topic) this.model.search = this.$route.query.topic

	},

	methods: {

		onToggleClick: function(type) {

			this.model[type] = !this.model[type]

			if (this.model[type]) this.model[(type === 'events') ? 'opinions' : 'events'] = false

		}

	}

}

</script>

<style scoped>

.filter {
	padding: 64px 0px;
}

.is-tablet .filter {
	padding: 40px 0px;
}

.is-mobile .filter {
	padding: 30px 0px;
}

.filter-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.filter-bar-label {
	font-size: 16px;
	line-height: 24px;
	color: #585A5D;
	margin-right: 20px;
}

.is-tablet .filter-bar-label,
.is-mobile .filter-bar-label {
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
}

.filter-bar-toggle {
	font-size: 16px;
	font-weight: 500;
	user-select: none;
	color: #112A45;
	padding: 0px 64px;
	line-height: 56px;
	border: 1px solid #BBD9CD;
	border-radius: 36px;
	margin-right: 20px;
	cursor: pointer;
}

.is-tablet .filter-bar-toggle,
.is-mobile .filter-bar-toggle {
	height: 48px;
	font-size: 14px;
	line-height: 48px;
	padding: 0px 40px;
}

.is-mobile .filter-bar-toggle {
	padding: 0px;
	width: calc(50% - 10px);
	text-align: center;
}

.is-mobile .filter-bar-toggle:nth-child(3) {
	margin-right: 0px;
}

.is-desktop .filter-bar-toggle:hover {
	background-color: #BBD9CD;
}

.filter-bar-toggle.is-active:hover,
.filter-bar-toggle.is-active {
	background-color: #112a45;
	color: #fff;
}

.filter-bar-search {
	width: 340px;
	border-bottom: 1px solid #BBD9CD;
	height: 48px;
	display: flex;
}

.is-tablet .filter-bar-search,
.is-mobile .filter-bar-search {
	flex-grow: 1;
	width: auto;
}

.is-mobile .filter-bar-search {
	width: 100%;
	margin-top: 20px;
}

.filter-bar-search input {
	width: 300px;
	font-size: 16px;
	font-weight: 300;
	padding: 0px 10px;
}

.is-tablet .filter-bar-search input,
.is-mobile .filter-bar-search input {
	flex-grow: 1;
}

.filter-bar-search i {
	width: 40px;
	line-height: 48px;
	font-size: 20px;
	flex-shrink: 0;
	color: #112A45;
}

</style>
